<template>
  <div style="text-align: center;">
    <router-link to="/invoice/create"><img alt="申请开票" src="../assets/invoice_ico.png"></router-link>
    
  </div>
</template>

<script>

export default {

}
</script>
