import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false

Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
    //beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {
        document.title = '新疆包邮到家-'+to.meta.title
    } else {
        document.title = '新疆包邮到家'
    }
    next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
