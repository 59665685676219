import Vue from 'vue'
import VueRouter from 'vue-router'
import indexView from '../views/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView,
	meta:{
		title:'首页'
	}
  },
  {
    path: '/invoice/create',
    name: 'invoiceCreate',
    component: () => import('../views/invoice/create'),
	meta:{
		title:'申请发票'
	}
  },
  {
    path: '/invoice/detail',
    name: 'invoiceDetail',
    component: () => import('../views/invoice/detail'),
	meta:{
		title:'发票详情'
	}
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/FeedBack.vue'),
	meta:{
		title:'意见建议'
	}
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/DownLoad.vue'),
	meta:{
		title:'下载'
	}
  },
  {
    path: '/navigation',
    name: 'navigation',
    component: () => import('../views/Navigation.vue'),
	meta:{
		title:'导航'
	}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
	meta:{
		title:'关于我们'
	}
  }
]

const router = new VueRouter({
  routes
})

export default router
